@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  /* Hide scrollbar for Chrome, Safari and Opera */
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  /* Hide scrollbar for IE, Edge and Firefox */
  .no-scrollbar {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */
  }
}

@import url("https://fonts.googleapis.com/css2?family=Inter:wght@400;500;600;700;800;900&display=swap");

* {
  scroll-behavior: smooth;
}

body {
  font-family: "Inter", sans-serif;
  background: #151419;
  color: white;
}

.bgGrade {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 255, 0.15) 0%,
    rgba(0, 0, 0, 0) 10.38%
  );
}

.bgGrade2 {
  background: linear-gradient(
    360deg,
    rgba(0, 0, 255, 0.09) 21.35%,
    rgba(0, 0, 0, 0) 99.99%,
    rgba(0, 0, 0, 0) 100%,
    rgba(0, 0, 0, 0) 100%
  );
}

.ctaGradient {
  background: linear-gradient(
    180deg,
    rgba(0, 0, 0, 0) 40%,
    rgba(0, 0, 20, 0.9) 65%,
    #00f 120%
  );
}

@media (min-width: 1024px) {
  .drop_shadow {
    box-shadow: 0px 2px 6px 0px rgba(18, 18, 19, 0.06);
  }
}

.drop-shadow-about {
  box-shadow: 0px 9px 42px 0px rgba(28, 28, 33, 0.38);
}

.custom-bounce {
  animation-duration: 1.5s; /* Adjust the animation duration as per your requirement */
  animation-timing-function: cubic-bezier(
    0.6,
    0.05,
    0.5,
    0.95
  ); /* Adjust the timing function for a softer bounce */
  animation-iteration-count: infinite;
  animation-name: bounce;
}

@keyframes bounce {
  0%,
  20%,
  50%,
  80%,
  100% {
    transform: translateY(0);
  }

  40% {
    transform: translateY(-10px); /* Adjust the height of the bounce */
  }

  60% {
    transform: translateY(-5px); /* Adjust the height of the bounce */
  }
}

.full-screen {
  height: 100vh;
  width: 100%;
  position: fixed;
  inset: 0;
  z-index: 9999;
}

.no-scroll {
  overflow: hidden;
}

.blog-content a {
  color: blue !important;
}

.loader {
  border: 4px solid #f3f3f3;
  border-top: 4px solid #3498db;
  border-radius: 50%;
  width: 16px;
  height: 16px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

